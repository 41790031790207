export async function authPost(accessToken: string, path: string, body: any) {
  const domain = import.meta.env.VITE_API_URL || '';
  const uri = domain + path;
  try {
    const res = await fetch(uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    });
    const name = await res.json();
    return name;
  } catch (err) {
    console.error(
      `POST request to uri ${uri} with body:\n${JSON.stringify(body, undefined, 4)} failed`
    );
    console.error(err);
    
    return;
  }
}

export async function authGet(accessToken: string, path: string) {
  const domain = import.meta.env.VITE_API_URL || '';
  const uri = domain + path;
  try {
    const res = await fetch(uri, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const name = await res.json();
    return name;
  } catch (err) {
    console.log(`GET request to uri ${uri} failed`);
    return;
  }
}
