import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from './Boards.module.css';

import ReportDisplay from "../../Components/Reports/reportDisplay";
import UploadFile from "../../Components/Reports/fileUpload";
import {useAuth0} from "@auth0/auth0-react"


export default function Reports() {
	return (
		<>
			<div className={classes.outer_class}>
			<h1 className={classes.title_class}>Missing Reports</h1>
				<ReportDisplay/>
			</div>
			<div >
				<UploadFile/>
			</div>
		</>
	);
}
