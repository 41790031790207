import { AppShell, Burger, Group, Skeleton, Text, Code, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MantineLogo } from '@mantinex/mantine-logo';
import NavbarSimple from '../NavBar/NavBar';
import { Outlet } from 'react-router-dom';

export default function Root() {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
      layout="default"
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <img
            src="/assets/TEA.png"
            height="40"
            style={{ backgroundColor: 'white' }}
          />
          <h2 style={{ margin: '0' }}>The Engagement Appeal</h2>
          <Code fw={700}>v3.1.2</Code>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <NavbarSimple />
      </AppShell.Navbar>
      <AppShell.Main>
        <Stack align="center">
          <Outlet />
        </Stack>
      </AppShell.Main>
    </AppShell>
  );
}
