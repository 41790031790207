import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTable, Column } from 'react-table';
import { boardOverview } from "../../interfaces/types";
import { authPost, authGet } from "../../auth/authFetches";
import {useAuth0} from "@auth0/auth0-react"
import classes from './Director.module.css';
import axios from 'axios';

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
}

async function getReply(accessToken: any, form_data: any) {
	console.log(`Fetching Jolt response`);
	//var jolt_response = authPost(accessToken, `/missingCompanies/modifyBoard`, form_data)
  return
}

export default function ReportDisplay() {
  const { getAccessTokenSilently } = useAuth0();
	
	const [reportList, setBoardData] = useState<boardOverview[]>();
  const [error, setError] = useState<string | null>(null);
  const [refetch, setRefetch] = useState<boolean>(false);
  const accesstoken = getAccessTokenSilently();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
 
  const fetchDirectorData = async () => {
    const accesstoken = await getAccessTokenSilently();
    try{
      const reports = await authGet(accesstoken, `/missingReports/missingReports`)
      setBoardData(reports);
    } catch (err) {
      setError('Failed to fetch report data.');
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDirectorData();
    
  }, [refetch]);

    console.log(reportList)
    
    const columns = [
        {
            Header: 'Company ID',
            accessor: 'companyId'
          },
          {
            Header: 'Company Name',
            accessor: 'companyName'
          },
    ];

    const TableWithForms: React.FC<TableProps<boardOverview>> = ({ columns, data }) => {
      const [formData, setFormData] = useState(data);

      const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
          setSelectedFile(e.target.files[0]);
        }
      };
   
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: formData
      });
    
      return (
        <div className="p-4">
          <table {...getTableProps()} className={classes.table_base}>
            <thead>
              {headerGroups.map(headerGroup  => (
                <tr {...headerGroup.getHeaderGroupProps()} className={classes.bottom_border}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className="p-2 text-left text-black">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={classes.bottom_border}>
                    {row.cells.map((cell, cellIndex) => (
                      <td {...cell.getCellProps()} className="p-2 text-black">
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    };

    return (
		<div className={classes.table_outer_div}>
                <div className={classes.table_inner_div}>
                    {reportList && (
                    <>
                    {/* @ts-ignore */}
                        <TableWithForms columns={columns} data={reportList} />
                    </>
			        )}
                </div>
		</div>
    )
}



