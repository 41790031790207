import { useState } from 'react';
import { Group, Code, Text } from '@mantine/core';
import {
  IconBellRinging,
  IconFingerprint,
  IconKey,
  IconAlertCircle,
  IconHome,
  IconSwitchHorizontal,
  IconLogout,
  IconLogin,
  IconLabelImportant,
} from '@tabler/icons-react';
import { MantineLogo } from '@mantinex/mantine-logo';
import classes from './NavbarSimple.module.css';
import { NavLink, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const data = [
  // { link: '', label: 'Home', icon: IconHome },
  // { link: '', label: 'Unprotected', icon: IconAlertCircle, clearance: '' },
  { link: '/', label: 'Missing Boards', icon: IconFingerprint, clearance: 'auth' },
  { link: '/reports', label: 'Missing Reports', icon: IconKey, clearance: 'auth' },
  // { link: '/protected', label: 'Protected', icon: IconKey, clearance: 'admin' },
];

export default function NavbarSimple() {
  const { user, logout } = useAuth0();

  const links = data.map((item) => {
    let show = false;
    if (item.clearance == '') {
      show = true;
    } else if (user && item.clearance == 'auth') {
      show = true;
    } else if (
      user &&
      item.clearance == 'admin' &&
      user[import.meta.env.VITE_AUTH0_AUDIENCE + '/roles'].includes('Admin')
    ) {
      show = true;
    }
    if (show) {
      return (
        <NavLink
          className={({ isActive, isPending, isTransitioning }) => {
            const hi = [isActive ? classes.active : '', classes.link].join(' ');
            console.log(isActive);
            console.log(hi);
            return hi;
          }}
          to={item.link}
          key={item.label}
        >
          <item.icon className={classes.linkIcon} stroke={1.5} />
          <span>{item.label}</span>
        </NavLink>
      );
    }
  });

  return (
    <>
      <div className={classes.navbarMain} id="active">
        {/* <Group className={classes.header} justify="space-between">
          <MantineLogo size={28} />
          <Code fw={700}>v3.1.2</Code>
        </Group> */}
        {links}
      </div>

      <div className={classes.footer}>
        {user ? (
          <>
            <Link
              to="#"
              className={classes.link}
              onClick={(event) => {
                event.preventDefault();
                logout({ logoutParams: { returnTo: process.env.VITE_AUTH0_LOGOUT_URL } });
              }}
            >
              <IconSwitchHorizontal className={classes.linkIcon} stroke={1.5} />
              <span>Change account</span>
            </Link>

            <a
              href="#"
              className={classes.link}
              onClick={(event) => {
                event.preventDefault();
                logout({ logoutParams: { returnTo: process.env.VITE_AUTH0_LOGOUT_URL } });
              }}
            >
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Log Out</span>
            </a>
          </>
        ) : (
          <Link
            to="/auth"
            className={classes.link}
            onClick={(event) => {
              event.preventDefault();
              logout({ logoutParams: { returnTo: process.env.VITE_AUTH0_LOGOUT_URL } });
            }}
          >
            <IconLogin className={classes.linkIcon} stroke={1.5} />
            <span>Log In</span>
          </Link>
        )}
      </div>
    </>
  );
}
