import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

import AuthLayout from './auth/AuthLayout';

import Root from './Components/Root';
import ErrorPage from './pages/Error';
import AuthRoot from './auth/AuthRoute';
import ProtectedRoute from './auth/ProtectedRoute';
import ProtectedPage from './pages/Protected/index';
import AuthPage from './pages/Auth';
import BoardPageWrapper from './pages/Boards/BoardPageWrapper';
import ReportPageWrapper from './pages/Reports/ReportPageWrapper';
import Unprotected from './pages/Unprotected';
import { HomePage } from './pages/Home/old';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AuthLayout />}>
      <Route path="/" element={<Root />}>
        {/* <Route index element={<Unprotected />} /> */}
        <Route path="/" element={<AuthRoot />}>
          <Route path="/" element={<ProtectedRoute />}>
            <Route path="auth" element={<AuthPage />} />
            <Route index={true} element={<BoardPageWrapper />} />
            <Route path="reports" element={<ReportPageWrapper />} />
            <Route index element={<ProtectedPage />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

export function Router() {
  return <RouterProvider router={router} />;
}
