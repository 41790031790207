import React, { useState, useEffect } from 'react';
import { useTable, Column } from 'react-table';
import { boardOverview } from "../../interfaces/types";
import { authPost, authGet } from "../../auth/authFetches";
import {useAuth0} from "@auth0/auth0-react"
import classes from './Director.module.css';

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
}

async function getReply(accessToken: any, form_data: any) {
	console.log(`Fetching Jolt response`);
	var jolt_response = authPost(accessToken, `/missingCompanies/modifyBoard`, form_data)
  return jolt_response
}

export default function BoardDisplay() {
  const { getAccessTokenSilently } = useAuth0();
	
	const [boardSites, setBoardData] = useState<boardOverview[]>();
  const [error, setError] = useState<string | null>(null);
  const [refetch, setRefetch] = useState<boolean>(false);
  const accesstoken = getAccessTokenSilently();
 
  const fetchDirectorData = async () => {
    const accesstoken = await getAccessTokenSilently();
    try{
      const boards = await authGet(accesstoken, `/missingCompanies/missingCompanies`)
      setBoardData(boards);
    } catch (err) {
      setError('Failed to fetch board data.');
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDirectorData();
    
  }, [refetch]);

    console.log(boardSites)
    
    const columns = [
        {
            Header: 'Company ID',
            accessor: 'companyId'
          },
          {
            Header: 'Company Name',
            accessor: 'companyName'
          },
          {
            Header: 'Current URL',
            accessor: 'companyURL'
          }
    ];

    const TableWithForms: React.FC<TableProps<boardOverview>> = ({ columns, data }) => {
      const [formData, setFormData] = useState(data);
    
      const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const updatedFormData = formData.map((item, idx) =>
          idx === index ? { ...item, [name]: value } : item
        );
        setFormData(updatedFormData);
      };
    
      const handleSubmit = async (index: number, event: React.FormEvent) => {
        event.preventDefault();
        try {
            const accesstoken = await getAccessTokenSilently();
            await getReply(accesstoken, formData[index]);
            console.log(`Submitted data: ${JSON.stringify(formData[index])}`);
            setTimeout(() => {
                setRefetch((prev) => !prev); // Toggle the refetch state to trigger useEffect
            }, 10000); // 10-second delay
            console.log("Reloaded");
        } catch (err) {
            console.log("Error submitting data", err);
        }
    };
    
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: formData
      });
    
      return (
        <div className="p-4">
          <table {...getTableProps()} className={classes.table_base}>
            <thead>
              {headerGroups.map(headerGroup  => (
                <tr {...headerGroup.getHeaderGroupProps()} className={classes.bottom_border}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className="p-2 text-left text-black">
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={classes.bottom_border}>
                    {row.cells.map((cell, cellIndex) => (
                      <td {...cell.getCellProps()} className="p-2 text-black">
                        {cell.column.id === 'companyURL' ? (
                          <input
                            type={cell.column.id === 'companyURL' ? 'companyURL' : 'text'}
                            name={cell.column.id}
                            value={formData[rowIndex][cell.column.id as keyof boardOverview]}
                            onChange={(e) => handleInputChange(rowIndex, e)}
                            className={classes.text_input}
                            placeholder={`Enter ${cell.column.id}`}
                          />
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    ))}
                    <td className="p-2 items-center">
                      <button
                        onClick={(e) => handleSubmit(rowIndex, e)}
                        className={classes.submit_button}
                      >
                        Submit
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    };

    return (
		<div className={classes.table_outer_div}>
                <div className={classes.table_inner_div}>
                    {boardSites && (
                    <>
                    {/* @ts-ignore */}
                        <TableWithForms columns={columns} data={boardSites} />
                    </>
			        )}
                </div>
		</div>
    )
}


