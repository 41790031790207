import { authGet, authPost } from '../auth/authFetches';

export async function fetchUnprotectedData() {
  const domain = import.meta.env.VITE_API_URL || '';
  const uri = domain + '/unprotectedData';
  try {
    const res = await fetch(uri);
    const name = await res.json();
    return name;
  } catch (err) {
    console.log(`GET request to uri ${uri} failed`);
    return;
  }
}

export async function fetchAuthData(accessToken: string) {
  const path = `/authData`;
  return await authGet(accessToken, path);
}

export async function fetchProtectedData(accessToken: string) {
  const path = `/protectedData`;
  return await authGet(accessToken, path);
}

export async function fetchProtectedDataPost(accessToken: string, action: string) {
  const path = `/protectedData/post`;
  const body = { action };
  const result = await authPost(accessToken, path, body);
  return result;
}
