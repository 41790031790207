import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { PageLoader } from '../Components/PageLoader';

export default function AuthRoot() {
  const Component = withAuthenticationRequired(
    () => {
      return <Outlet />;
    },
    {
      onRedirecting: () => (
        <div className="page-layout">
          <PageLoader />
        </div>
      ),
    }
    // {
    // 	loginOptions: {
    // 		fragment: "",
    // 	},
    // }
  );

  return <Component />;
}
