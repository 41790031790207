import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import classes from './Boards.module.css';

import BoardDisplay from "../../Components/Directors/directorsDisplay";
import {useAuth0} from "@auth0/auth0-react"


export default function Boards() {
	return (
		<>
			<div className={classes.outer_class}>
			<h1 className={classes.title_class}>Missing Boards</h1>
				<BoardDisplay/>
			</div>
		</>
	);
}
