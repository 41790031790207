import { PropsWithChildren, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export default function ProtectedRoute() {
  const { user } = useAuth0();
  const role_property = import.meta.env.VITE_AUTH0_AUDIENCE + '/roles';
  const [roles, setRoles] = useState<string[] | undefined>(user && user[role_property]);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(user);
    const role_property = import.meta.env.VITE_AUTH0_AUDIENCE + '/roles';
    const roles = user && user[role_property];
    setRoles(roles);
  }, [user]);

  if (roles && roles.includes('Admin')) {
    return <Outlet />;
  } else {
    setTimeout(() => {
      navigate('/');
    }, 4000);
    return (
      <div>You do not have the permission to view this page, please contact an administrator so they can give you the appropriate access</div>
    );
    return <Navigate to="/" />;
  }
}
