import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { fetchProtectedData } from '../../services/fetchExamples';
import StatsBar from '@/Components/StatsBar';
import COLORS from '@/data/colors';

import { Center } from '@mantine/core';
import { GraphOptionsBase, SingleCategoricalData } from '../../interfaces/types';

export default function ProtectedPage() {
  const [graphData, setGraphData] =
    useState<{ data: SingleCategoricalData; graphOptions: GraphOptionsBase }[]>();
  const [stats, setStats] = useState();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    async function getData() {
      const accessToken = await getAccessTokenSilently();
      const fetchedData = await fetchProtectedData(accessToken);
      const { graphData: fetchedGraphData, statData: fetchedStatData } = fetchedData;
      setGraphData(fetchedGraphData);
      setStats(fetchedStatData);
      console.log(fetchedGraphData);
      console.log(fetchedStatData);
    }
    getData();
  }, []);

  //   if (data) {
  //     const summaryStats = data.stats
  //     const stats = [
  //         {
  //         name: 'Reach',
  //         value: summaryStats.Reach,
  //         },
  //         {
  //         name: 'Click-Through rate',
  //         value: summaryStats['Click-Through Rate'],
  //         suffix: ' %',
  //         decimalPlaces: 2,
  //         },
  //         {
  //         name: 'Cost Per Click',
  //         value: summaryStats['Cost Per Click'],
  //         prefix: '$ ',
  //         decimalPlaces: 2,
  //         },
  //         {
  //         name: 'Clicks',
  //         value: summaryStats['Clicks'],
  //         decimalPlaces: 1,
  //         },
  //     ]
  // }

  return <div className="flex flex-col items-center ">Protected</div>;
}
