import React, { useState, ChangeEvent } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import classes from './Director.module.css';
import { authGet, authPost } from '@/auth/authFetches';


export default function UploadFile() {
    const { getAccessTokenSilently } = useAuth0();
    const [uploading, setUploading] = useState<boolean>(false);
    const [presignedUrl, setPresignedURL] = useState<any>();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const getPresignedUrl = async (parameters: any) => {
      const accesstoken = await getAccessTokenSilently();
      try{
        const URL = await authPost(accesstoken, `/missingReports/uploadURL`, parameters)
        return URL
      } catch (err) {
        console.log(err);
      }
    };

    const uploadFile = async () => {
        if (!selectedFile) return;

        setUploading(true);

        const params = {
          fileType: selectedFile.type,
          fileName: selectedFile.name
        }

        const newURL = await getPresignedUrl(params);
        console.log(newURL)

        try {
            const response = await fetch(newURL.toString(), {
                method: 'PUT',
                headers: {
                    'Content-Type': selectedFile.type,
                },
                body: selectedFile,
            });

            if (!response.ok) {
                throw new Error('Failed to upload file');
            }
            console.log('File uploaded successfully');
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    return (
        <div className={classes.outer_class}>
            <div>
                <input type="file" onChange={handleFileInput} />
                <button onClick={uploadFile} disabled={uploading || !selectedFile}>
                    {uploading ? 'Uploading...' : 'Upload'}
                </button>
            </div>
        </div>
    );
}
