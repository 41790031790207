import { fetchAuthData } from '../../services/fetchExamples';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function AuthPage() {
  const [authData, setAuthData] = useState();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getAuthData = async () => {
      const accessToken = await getAccessTokenSilently();
      const authDataFetch = await fetchAuthData(accessToken);
      setAuthData(authDataFetch);
      console.log(authDataFetch);
    };
    getAuthData();
  }, []);

  return <h1>Authentication</h1>;
}
